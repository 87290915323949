<template>
  <v-main style="background-color: #fff" class="mt-16">
    <div class="container">
      <div>
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title">Pick the best plan for you</h2>
            <h5 class="description">
              You have Free Unlimited Updates and Premium Support on each
              package.
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="card card-plain card-pricing">
              <h6 class="card-category">Bravo Pack</h6>
              <!----><!---->
              <div class="card-body">
                <div class="card-icon icon-warning">
                  <i class="now-ui-icons media-1_button-power"></i>
                </div>
                <h3 class="card-title">10$</h3>
                <ul>
                  <li>Complete documentation</li>
                  <li>Working materials in Sketch</li>
                </ul>
              </div>
              <!---->
              <div class="card-footer">
                <button type="button" class="btn btn-round btn-primary">
                  <!---->Add to Cart<!---->
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="card card-pricing">
              <h6 class="card-category">Alpha Pack</h6>
              <!----><!---->
              <div class="card-body">
                <div class="card-icon icon-primary">
                  <i class="now-ui-icons objects_diamond"></i>
                </div>
                <h3 class="card-title">69$</h3>
                <ul>
                  <li>Working materials in EPS</li>
                  <li>6 months access to the library</li>
                </ul>
              </div>
              <!---->
              <div class="card-footer">
                <button type="button" class="btn btn-round btn-primary">
                  <!---->Add to Cart<!---->
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="card card-plain card-pricing">
              <h6 class="card-category">Charlie Pack</h6>
              <!----><!---->
              <div class="card-body">
                <div class="card-icon icon-success">
                  <i class="now-ui-icons media-2_sound-wave"></i>
                </div>
                <h3 class="card-title">69$</h3>
                <ul>
                  <li>Working materials in PSD</li>
                  <li>1 year access to the library</li>
                </ul>
              </div>
              <!---->
              <div class="card-footer">
                <button type="button" class="btn btn-round btn-primary">
                  <!---->Add to Cart<!---->
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="card card-plain card-pricing">
              <h6 class="card-category">Extra Pack</h6>
              <!----><!---->
              <div class="card-body">
                <div class="card-icon icon-danger">
                  <i class="now-ui-icons education_atom"></i>
                </div>
                <h3 class="card-title">159$</h3>
                <ul>
                  <li>Complete documentation</li>
                  <li>2GB cloud storage</li>
                </ul>
              </div>
              <!---->
              <div class="card-footer">
                <v-btn round  >
                  <!---->Add to Cart<!---->
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="mb-10 col col-12">
          <div class="d-flex">
            <!-- <v-img src="https://foodhub-nuxt.vercel.app/_nuxt/img/cover-img.0f418da.png">

            </v-img> -->
            <v-responsive style="width: 1206px">
              <div
                class="
                  d-flex
                  justify-center justify-md-space-between
                  align-center
                  mx-10
                  cover-image-content
                "
              >
                <div
                  class="
                    d-md-flex d-block
                    align-center
                    text-md-left text-center
                    flex-wrap
                  "
                >
                  <label for="avatarUpload" class="me-4"
                    ><div class="avatar-upload">
                      <v-avatar
                        style="height: 160px; min-width: 160px; width: 160px"
                      >
                        <img
                          src="	https://foodhub-nuxt.vercel.app/_nuxt/img/big-avatar.a6c4014.png"
                          alt=""
                        />
                      </v-avatar>
                      <button
                        type="button"
                        class="
                          avatar-upload-btn
                          v-btn
                          v-btn--is-elevated
                          v-btn--fab
                          v-btn--has-bg
                          v-btn--round
                          theme--dark
                          v-size--small
                          grey
                          lighten-4
                        "
                      >
                        <span class="v-btn__content"
                          ><i
                            aria-hidden="true"
                            class="
                              v-icon
                              notranslate
                              mdi mdi-camera
                              theme--dark
                              secondary--text
                            "
                            style="font-size: 20px"
                          ></i
                        ></span>
                      </button>
                      <input
                        type="file"
                        id="avatarUpload"
                        class="d-none"
                      /></div
                  ></label>
                  <div>
                    <h2 class="white--text text-md-left text-center mb-1">
                      Abriella Bond
                    </h2>
                    <div class="d-flex mb-1 justify-center justify-md-start">
                      <i
                        aria-hidden="true"
                        class="
                          v-icon
                          notranslate
                          me-2
                          mdi mdi-map-marker
                          theme--light
                          black--text
                        "
                        style="font-size: 15px"
                      ></i>
                      <h6 class="font-weight-light white--text">
                        New York, US
                      </h6>
                    </div>
                    <h6 class="font-weight-light white--text">
                      Bigtime foodie..out and out dessert person
                    </h6>
                  </div>
                </div>
                <div class="d-none d-md-flex">
                  <div class="text-center">
                    <h2 class="font-weight-bold white--text">24</h2>
                    <h6 class="font-weight-regular white--text">Reviews</h6>
                  </div>
                  <div class="mx-3">
                    <hr
                      role="separator"
                      aria-orientation="vertical"
                      class="v-divider v-divider--vertical theme--dark"
                    />
                  </div>
                  <div class="text-center">
                    <h2 class="font-weight-bold white--text">45</h2>
                    <h6 class="font-weight-regular white--text">Photos</h6>
                  </div>
                  <div class="mx-3">
                    <hr
                      role="separator"
                      aria-orientation="vertical"
                      class="v-divider v-divider--vertical theme--dark"
                    />
                  </div>
                  <div class="text-center">
                    <h2 class="font-weight-bold white--text">1.6k</h2>
                    <h6 class="font-weight-regular white--text">Followers</h6>
                  </div>
                </div>
              </div>
            </v-responsive>
          </div>
        </div>
      </div>
    </div>

    <div
      class="v-dialog v-dialog--active"
      style="transform-origin: center center; width: 500px"
    >
      <div data-v-42ba0752="" class="v-card v-sheet theme--light">
        <div class="px-3 px-md-10 py-8">
          <h3 class="mb-2 text-center">Welcome To Ecommerce</h3>
          <h5
            class="font-600 grey--text text--darken-3 text-sm mb-9 text-center"
          >
            Log in with email &amp; password
          </h5>
          <p class="text-14 mb-1">Email or Phone Number</p>
          <div
            class="
              v-input
              mb-4
              v-input--hide-details v-input--dense
              theme--light
              v-text-field
              v-text-field--is-booted
              v-text-field--enclosed
              v-text-field--outlined
              v-text-field--placeholder
            "
          >
            <div class="v-input__control">
              <div class="v-input__slot">
                <fieldset aria-hidden="true">
                  <legend style="width: 0px">
                    <span class="notranslate">​</span>
                  </legend>
                </fieldset>
                <div class="v-text-field__slot">
                  <input
                    id="input-962"
                    placeholder="example@mail"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <p class="text-14 mb-1">Password</p>
          <div
            class="
              v-input
              mb-4
              v-input--hide-details v-input--dense
              theme--light
              v-text-field
              v-text-field--is-booted
              v-text-field--enclosed
              v-text-field--outlined
              v-text-field--placeholder
            "
          >
            <div class="v-input__control">
              <div class="v-input__slot">
                <fieldset aria-hidden="true">
                  <legend style="width: 0px">
                    <span class="notranslate">​</span>
                  </legend>
                </fieldset>
                <div class="v-text-field__slot">
                  <input
                    id="input-963"
                    placeholder="example@mail"
                    type="password"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="
              text-capitalize
              font-600
              v-btn v-btn--block v-btn--is-elevated v-btn--has-bg
              theme--light
              v-size--default
            "
            style="background: #d23f57"
          >
            <span class="v-btn__content" style="color: white !important"
              >Login</span
            >
          </button>
          <div class="mx-auto col-lg-8 col-10">
            <div class="d-flex align-center my-1">
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--light"
              />
              <span class="mx-4">on</span>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--light"
              />
            </div>
          </div>
          <button
            type="button"
            class="
              text-capitalize
              font-600
              mb-4
              v-btn v-btn--block v-btn--is-elevated v-btn--has-bg
              theme--dark
              v-size--default
              indigo
            "
          >
            <span class="v-btn__content"
              ><i
                aria-hidden="true"
                class="
                  v-icon
                  notranslate
                  me-3
                  v-icon--left
                  mdi mdi-facebook
                  theme--dark
                "
              ></i>
              Continue with facebook
            </span>
          </button>
          <button
            type="button"
            class="
              text-capitalize
              font-600
              mb-4
              v-btn v-btn--block v-btn--is-elevated v-btn--has-bg
              theme--dark
              v-size--default
              blue
              darken-2
            "
          >
            <span class="v-btn__content"
              ><i
                aria-hidden="true"
                class="
                  v-icon
                  notranslate
                  me-3
                  v-icon--left
                  mdi mdi-google
                  theme--dark
                "
              ></i>
              Continue with Google
            </span>
          </button>
          <div class="text-14 text-center my-3" style="font-size: 12pt">
            Don't have account?
            <a href="/SignUp" class="grey--text text--darken-4 font-600"
              >Sign Up</a
            >
          </div>
        </div>
        <div class="py-4" style="background-color: #f6f9fc; font-size: 12pt">
          <div class="text-center">
            <span class="grey--text text--darken-1"
              >Forgot Your Password
              <a
                href="/"
                class="ms-2 grey--text text--darken-4 font-600 nuxt-link-active"
                >Reset It</a
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";

import Vue from "vue";
const fb = require("../../firebaseConfig.js");

export default {
  data: () => ({
    mytrips: [],
  }),
  components: {},
  computed: {
    // sales: get('sales/sales'),
    // totalSales () {
    //   return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
    // },
  },
  created() {
    fb.db
      .collection("mytrips")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        this.mytrips = [];
        querySnapshot.forEach((doc) => {
          const object = doc.data();
          object.id = doc.id;
          this.mytrips.push(object);
        });
      });
  },
  methods: {
    _goDetail() {
      console.log("dklfjdfj");
      this.$router.push({ name: "TripDetail", params: { tripId: 11 } });
    },
  },
};
</script>
<style>
.card-pricing {
    text-align: center;
}
.card-plain {
    background: transparent;
    box-shadow: none;
}
.card {
    border: 0;
    border-radius: .1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
}
.card .card-footer {
    background-color: transparent;
    border: 0;
}
.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}
.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,.125);
}
</style>